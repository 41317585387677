/* Accordion styles */
.service-container {
    margin-top: 150px;
}

.input-radio {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

.tab {
    width: 100%;
    color: white;
    overflow: hidden;
}

.tab-label {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(5px);
    border-radius: 6px;
    cursor: pointer;
    /* Icon */
}


.tab-content {
    max-height: 0px;
    background: transparent;
}

input:checked+.tab-label::after {
    transform: rotate(90deg);
}

input:checked~.tab-content {
    max-height: 100%;
    padding: 20px;
    padding-right: 24px;
}