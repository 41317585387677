.desktop-hero-img, .mobile-hero-img {
    display: none;
}

.mobile-hero-img {
    display: none;
    margin: 0px auto;
}

.hero-text {
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-text p {
    font-family: MazzardH-Regular;
}

.greenLogoText {
    color: #06d6a0;
    font-size: 55px;
    font-weight: 800;
    margin-left: 4px;
}

.r-svg {
    display: inline-block;
}

/* Create the glitch effect for p.p1 */
.hero-text p.p1 {
    color: white;
    position: relative;
  }
  
  .hero-text p.p1::before,
  .hero-text p.p1::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
  }
  
  .hero-text p.p1::before {
    left: 2px;
    text-shadow: -1px 0 red;
    animation: glitch-anim-1 3s infinite linear alternate-reverse;
  }
  
  .hero-text p.p1::after {
    left: -2px;
    text-shadow: 2px 0 blue;
    animation: glitch-anim-2 3s infinite linear alternate-reverse;
  }
  
  /* Define the keyframes for glitch-anim-1 and glitch-anim-2 */
  @keyframes glitch-anim-1 {
    0% { clip-path: inset(40% 0 61% 0); }
    20% { clip-path: inset(92% 0 1% 0); }
    40% { clip-path: inset(43% 0 1% 0); }
    60% { clip-path: inset(25% 0 58% 0); }
    80% { clip-path: inset(54% 0 7% 0); }
    100% { clip-path: inset(58% 0 43% 0); }
  }
  
  @keyframes glitch-anim-2 {
    0% { clip-path: inset(42% 0 59% 0); }
    20% { clip-path: inset(90% 0 3% 0); }
    40% { clip-path: inset(45% 0 1% 0); }
    60% { clip-path: inset(27% 0 56% 0); }
    80% { clip-path: inset(50% 0 9% 0); }
    100% { clip-path: inset(60% 0 40% 0); }
  }

@media only screen and (min-width: 721px) {
    .desktop-hero-img {
        display: block;
        margin-top: -150px;
        margin-bottom: -50px;
    }

    .hero-text { 
        bottom: 0px;
    }
    
    .hero-text .p1 {
        font-size: 26px;
        font-weight: 400;
        line-height: 30px;
    }

    .hero-text .p2 {
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
    }
}

@media only screen and (max-width: 1114px) {
    .hero-text { 
        bottom: -60px;
    }

}

@media only screen and (max-width: 720px) {
    .mobile-hero-img {
        display: block;
    }

    .hero-text{
        margin-top: -40px;
    }

    .greenLogoText {
        font-size: 32px;
    }

    .r-svg {
        width: 35px;
        height: 35px;
    }

    .hero-text p {
        line-height: 5px;
    }

    .hero-text .p0 {
        height: 70px;
    }

    .hero-text .p1 {
        font-size: 16px;
        font-weight: 400;
    }

    .hero-text .p2 {
        font-size: 12px;
        font-weight: 500;
    }

}

@media only screen and (max-width: 630px) {
    .mobile-hero-img {
        display: block;
    }

    .hero-text{
        margin-top: -40px;
    }

    .hero-text p {
        line-height: 5px;
    }

    .hero-text .p0 {
        height: 70px;
    }

    .hero-text .p1 {
        font-size: 18px;
        font-weight: 400;
    }

    .hero-text .p2 {
        font-size: 12px;
        font-weight: 500;
    }

}