.trusted-by-wrapper {
    width: 700px; /* Set the width of the wrapper */
    margin: 0 auto; /* Center the wrapper */
    position: relative; /* For the dark layer positioning */
    overflow: hidden; /* Hide the overflow */
    margin-top: 30px;
}

.scroll-container {
    display: flex;
    align-items: center;
    overflow-x: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    gap: 100px; /* Space between logos */
    animation: scroll 60s linear infinite; /* Time for one scroll loop */
}

.scroll-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

/* Remove the keyframes animation since we're using JavaScript to handle the scrolling */

.logo {
    min-width: 50px; /* Width of the logo */
    height: 50px; /* Height of the logo */
    flex: 0 0 auto; /* Prevent logos from stretching */
    position: relative; /* For the dark layer positioning */
}

.logo::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark layer with 50% opacity */
}

.trusted-by {
    font-family: MazzardH-Regular;
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 50px;
    font-weight: 400;
}


@media screen and (max-width: 768px) {
    .trusted-by-wrapper {
        width: 100%;
    }

    .trusted-by {
        margin-top: 100px;
    }
}