.opt-box {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(5px);
}

.number {
    background: linear-gradient(97.22deg, #57AEFF 3.32%, #1CE1AE 91.86%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* text-fill-color: transparent; */
    font-style: normal;
    font-weight: 900;
    font-size: 200px;
    position: absolute;
    right: 0px;
    margin-top: 10px;
}

.bottom-strip {
    flex: 1;
    height: 59px;
}

.activeStrip {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(5px);
}

.mobileBox {
    background: #141414;
    border: 1px solid #606060;
    box-shadow: 0px 0px 20px 10px #000000;
}

.swiper-slide-active .strip {
    display: block;
}

.operationsSwiper .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 100% !important;
}