:root {
  --primary-color: #06D6A0;
}

@font-face {
  font-family: MazzardH-Regular;
  src: url(assets/fonts/MazzardH-Light.otf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: MazzardH-Regular;
  src: url(assets/fonts/MazzardH-Medium.otf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: MazzardH-Regular;
  src: url(assets/fonts/MazzardH-Bold.otf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: MazzardH-Regular;
  src: url(assets/fonts/MazzardH-ExtraBold.otf);
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: MazzardH-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.blur-box {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(5px);
}

.lightBorder-right {
  border-color: rgba(255, 255, 255, 0.1);
}

.border-box-light {
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 6px;
}

.common-radio {
  accent-color: white;
  height: 20px;
  width: 20px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #60606B;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #60606B;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #60606B;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide-active {
  box-shadow: 0px 0px 20px 10px #000000;
}

.swiper-slide-active .mobileBox {
  box-shadow: none;
}

.skytogreen {
  background: linear-gradient(97.22deg, #57AEFF 3.32%, #1CE1AE 91.86%);
  filter: blur(300px);
}

.cyanTopPurple {
  background: linear-gradient(98.24deg, #6DDCFF 0%, #7F60F9 100%);
  filter: blur(250px);
}