.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.loader-holder {
    display: flex;
    gap: 20px;
    height: 50px;
    overflow: hidden;
}

.animate-frame {
    animation: animate 10s linear infinite;
}

p {
    font-family: MazzardH-Regular;
    font-size: 16px;
    padding: 12px 0px;
    font-weight: 600;
}

@media screen and (max-width: 635px) {
    .loader-logo{
        position: absolute;
        left: 42.5%;
        right: 42.5%;
        width: 15%;
        margin-top: -22%;
    }

    .animate-frame p{
        text-align: center;
    }
}

@keyframes animate {
    100% {
        transform: translateY(-100%);
    }
}